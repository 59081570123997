var mixin = {
  methods: {
    regexNumberInt(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    regexNumber(evt) {
      // const regex = /^([0-9]*[.])?[0-9]+/;

      // if (evt.target.value.match(regex)) {
      //   return true;
      // } else {
      //   evt.preventDefault();
      // }

      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};

export default mixin;
