<template>
  <Default>
    <banner :fondo="fondo" :titulo="titulo" descripcion=""></banner>

    <section class="main__content mb-5">
      <div class="card">
        <div class="card-header d-flex align-items-center">
          <p class="header-card">{{ seccionDetalle }}</p>
        </div>
        <div class="card-body">
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 ">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </section>
  </Default>
</template>

<script>
import Default from "./default.vue"
import Banner from "@/components/global/Banner";

export default {
  components: {
    Banner, Default
  },
  props: {
    fondo: {
      type: String,
    },
    titulo: {
      type: String,
    },
    seccionDetalle: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.header-card {
  font-size: 16px;
}

@media (min-width: 768px) {
  .header-card {
    font-size: 26px;
  }
}
</style>
