<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="atencion">Nombre del Personal de atención</label>
          <input
            type="text"
            id="atencion"
            class="form-control"
            v-model="atencion"
          />
        </div>
      </div>

      <div class="col-12 col-md-4">
        <div class="form-group">
          <label for="celularAtencion">Celular del Personal de atención</label>
          <input
            type="text"
            id="celularAtencion"
            class="form-control"
            v-model="celularAtencion"
          />
        </div>
      </div>
      <div class="col-12 col-md-8">
        <div class="form-group">
          <label for="correoAtencion">Correo del Personal de atención</label>
          <input
            type="text"
            id="correoAtencion"
            class="form-control"
            v-model="correoAtencion"
          />
        </div>
      </div>

      <div class="col-12">
        <div class="form-group">
          <label for="observaciones">Observaciones</label>
          <textarea
            id="observaciones"
            class="form-control"
            v-model="observaciones"
          ></textarea>
        </div>
      </div>

      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label for="numerodeTecnicos">Nro de técnicos</label>
          <input
            type="number"
            min="0"
            class="form-control"
            v-model="numerodeTecnicos"
            @keypress="regexNumberInt"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-8">
        <div class="form-group">
          <label for="comentariosAdicionales">Comentarios adicionales</label>
          <textarea
            id="comentariosAdicionales"
            class="form-control"
            v-model="comentariosAdicionales"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
const { required, email } = require("vuelidate/lib/validators");
import regexMixins from "@/mixins/regexMixins.js";

export default {
  mixins: [validationMixin, regexMixins],
  props: { asignacion: { type: Object } },
  data() {
    return {
      atencion: "",
      celularAtencion: "",
      correoAtencion: "",
      observaciones: "",
      numerodeTecnicos: null,
      comentariosAdicionales: "",
    };
  },

  validations: {
    atencion: {
      required,
    },
    celularAtencion: {
      required,
    },
    correoAtencion: {
      required,
      email,
    },
    observaciones: {
      required,
    },
    numerodeTecnicos: {
      required,
    },
    comentariosAdicionales: {
      required,
    },
  },

  mounted() {
    const { celular, emailContacto } = this.asignacion.local;

    this.atencion =
      this.asignacion &&
      this.asignacion.local &&
      this.asignacion.local.personalAtencion
        ? this.asignacion.local.personalAtencion
        : "";

    this.celularAtencion = celular;
    this.correoAtencion = emailContacto;
  },

  watch: {
    correoAtencion(){
      this.correoAtencion = this.correoAtencion.toLowerCase().trim()
    }
  },

  methods: {
    validate() {
      this.correoAtencion = this.correoAtencion.toLowerCase().trim();

      this.$v.$touch();
      var isValid = !this.$v.$invalid;
      this.$emit("on-validate", this.$data, isValid);

      if (!isValid) {
        const h = this.$createElement;

        const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
          h("b-spinner", { props: { type: "grow", small: true } }),
          ` Parece que hay campos faltantes o se ha ingresado mal algun campo, por favor verifique ello e intentelo nuevamente. `,
          h("b-spinner", { props: { type: "grow", small: true } }),
        ]);
        // Create the title
        const vNodesTitle = h(
          "div",
          {
            class: ["d-flex", "flex-grow-1", "align-items-baseline", "mr-2"],
          },
          [h("strong", { class: "mr-2" }, "Datos del personal incompletos")]
        );

        this.$bvToast.toast([vNodesMsg], {
          title: [vNodesTitle],
          solid: true,
          variant: "danger",
        });
      }

      return isValid;
    },
  },
};
</script>
